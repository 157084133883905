import React, {useEffect, useState} from 'react';
import classes from './CallPage.module.css';
import Title from '../../Component/Title/Title';
import CallButton from '../../Component/buttons/CallButton/CallButton';
import OfficeStatus from '../../Component/Statuses/OfficeStatus/OfficeStatus';
import RemoteStatus from '../../Component/Statuses/RemoteStatus/RemoteStatus';
import CancelButton from '../../Component/buttons/CancelButton/CancelButton';
import {
  callToMeet,
  cancelSession,
  getActiveSession,
  getParticipants,
  getUserData,
  markAsRemote
} from '../../apiClient/apiClient';
import {redirect, useNavigate} from 'react-router-dom';
import ParticipantsList from '../../Component/ParticipantsList/ParticipantsList';
import ArrowSvg from '../../Component/SVG/ArrowSvg/ArrowSvg';
import {socket} from '../../Socket';

const CallPage = ({ tg }) => {

  const userName = tg.initDataUnsafe.user ? tg.initDataUnsafe.user['first_name'] : 'user';
  const chatId = tg.initDataUnsafe?.user?.id ?? 123456;

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [isCallButtonPressed, setIsCallButtonPressed] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [isArrowUp, setIsArrowUp] = useState(true);
  const [isParticipantsListHidden, setIsParticipantsListHidden] = useState(false);

  useEffect(() => {
    getUserData(chatId)
      .then((response) => {
        if (response.user) {
          setFirstName(response.user.firstName);

          if (response.user.isFirstVisit) {
            navigate(`/registration/${response.user.id}`);
          }

          if (response.user.remoteAt !== null && new Date(response.user.remoteAt) >= new Date()) {
            navigate('/remote')
          }
        }
      });

    getActiveSession()
      .then((response) => {
        if (response.session !== null) {
          navigate(`/invite/${response.session.id}`);
        }
      })

    getParticipants(chatId)
      .then((response) => {
        setParticipants(response.items)
      });

    socket.connect();
    socket.emit('chatId', chatId);

    socket.on('newSession', (newSessionId) => navigate(`/invite/${newSessionId}`));

    return () => {
      socket.off('newSession');
      socket.disconnect();
    }
  }, [])

  const hideParticipantsList = (event) => {
    setIsArrowUp((prevState) => !prevState);
    setIsParticipantsListHidden((prevState) => !prevState);
  }

  const markAsRemoteAction = (event) => {
    markAsRemote(chatId)
    .then((response) => {
      if (response !== 'success') {
        console.log('error with markAsRemote');
      }

      navigate('/remote');
    })
    .catch((err) => {
      console.error(err);
    })
  }

  const callToMeetAction = (event) => {
    callToMeet(chatId)
      .then((newSessionId) => {
        setIsCallButtonPressed(true);
        navigate(`/session/${newSessionId}`);
      })
  }

  return (
    <div className={classes.callPage}>
      <div style={{display: "flex", height: "7.43%"}}>
        <Title/>
      </div>

      <div className={classes.mainInfoBlock}>

        <div
          style={isCallButtonPressed ? { display: 'none' } : {display: "flex", flexDirection: "column"}}
        >
          <span className={classes.textHallo}>👋🏻 Привет, {firstName}</span>

          <div className={classes.statusBlock}>
            <span className={classes.textStatus}>Твой статус на базе:</span>

            <div className={classes.blockChooseStatus}>
              <OfficeStatus isActive={true}/>
              <RemoteStatus isActive={false} onClickAction={markAsRemoteAction}/>
            </div>
          </div>
        </div>

        <div className={classes.participantBlock}>
          <span
            className={classes.participantsTitleText}
            onClick={hideParticipantsList}
          >
            Список курильщиков <ArrowSvg isUp={isArrowUp}/>
          </span>
          <ParticipantsList participants={participants} isHidden={isParticipantsListHidden}/>
        </div>

      </div>

      <div className={classes.buttonBlock}>
        <CallButton
          onClickAction={callToMeetAction}
          isPressed={isCallButtonPressed}
        >
          Позвать курить
        </CallButton>
      </div>

    </div>
  );
};

export default CallPage;