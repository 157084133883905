export function checkParticipantIsVote(chatId, participantsList) {
  const participant = participantsList.find((item) => item.chatId === chatId);

  if (!participant) {
    console.error('Participant not found');
    return false;
  }

  if (participant.status !== 'awaiting') {
    return true;
  }

  return false;
}