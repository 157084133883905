import React from 'react';
import classes from './OfficeStatus.module.css';

const OfficeStatus = ({ isActive, onClickAction }) => {
  return (
    <div
      className={isActive ? classes.bodyStatus : classes.bodyInactiveStatus}
      onClick={onClickAction}
    >
      <span className={isActive ? classes.statusText : classes.statusInactiveText}>Офис ✅</span>
    </div>
  );
};

export default OfficeStatus;