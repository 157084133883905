const statusOrder = {
  'accept': 1,
  'reject': 2,
  'awaiting': 3,
}

export function updateParticipantStatus(participantList, chatId, status, setTitleAction) {
  let votedParticipantsCount = 0;
  const participants = participantList.map((item) => {
    if (item.chatId === chatId) item.status = status;
    if (item.status !== 'awaiting') votedParticipantsCount++;
    return item;
  });

  if (votedParticipantsCount === participants.length) setTitleAction('Все готовы. Погнали!');

  return participants.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
}