import React, {useEffect, useState} from 'react';
import classes from './AwaitingPage.module.css'
import Title from '../../Component/Title/Title';
import ParticipantsList from '../../Component/ParticipantsList/ParticipantsList';
import {useNavigate, useParams} from 'react-router-dom';
import {cancelSession, getSession} from '../../apiClient/apiClient';
import CancelButton from '../../Component/buttons/CancelButton/CancelButton';
import {socket} from '../../Socket';
import {updateParticipantStatus} from './utils/updateParticipantStatus';
import {checkAllParticipantsVoted} from './utils/checkAllParticipantsVoted';

const AwaitingPage = ({ tg }) => {
  const chatId = tg.initDataUnsafe?.user?.id ?? 123456;

  const navigate = useNavigate();

  const { sessionId } = useParams();

  const [participants, setParticipants] = useState([]);
  const [isInitiatorsSide, setIsInitiatorsSide] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    getSession(sessionId)
      .then((response) => {
        if (response === 'notFound') {
          navigate('/notFound');
        }

        setParticipants(response.participants);
        setIsInitiatorsSide(response.initiatorChatId === chatId);
        setTitle(response.initiatorChatId === chatId ? '🫵🏻 Вы вызвали на перекур' : '🕑 Ждем остальных...');
        if (checkAllParticipantsVoted(response.participants)) setTitle('Все готовы. Погнали!');
      });

    socket.connect();
    socket.emit('chatId', chatId);

    socket.on('updateParticipantStatus', (chatId, newStatus) => {
      setParticipants(prevState => updateParticipantStatus(prevState, chatId, newStatus, setTitle));
    });

    socket.on('cancelSession', (canceledSessionId) => {
      navigate('/notFound');
    })

    return () => {
      socket.off('updateParticipantStatus');
      socket.off('cancelSession');
      socket.disconnect();
    }
  }, []);

  const cancelMeetAction = (event) => {
    cancelSession(sessionId)
      .then(() => navigate('/'))
  }

  return (
    <div className={classes.pageBackground}>
      <Title/>

      <span className={classes.text}>
        {title}
      </span>

      <ParticipantsList participants={participants} isHidden={false}/>

      <CancelButton
        text={"Отменить перекур"}
        isHidden={!isInitiatorsSide}
        onClickAction={cancelMeetAction}
      />
    </div>
  );
};

export default AwaitingPage;