import React, {useEffect, useState} from 'react';
import classes from './RemotePage.module.css'
import Title from '../../Component/Title/Title';
import InOfficeButton from '../../Component/buttons/InOfficeButton/InOfficeButton';
import {useNavigate} from 'react-router-dom';
import {getUserData, markAsOffice} from '../../apiClient/apiClient';
import OfficeStatus from '../../Component/Statuses/OfficeStatus/OfficeStatus';
import RemoteStatus from '../../Component/Statuses/RemoteStatus/RemoteStatus';
import CommonButton from '../../Component/buttons/CommonButton/CommonButton';

const RemotePage = ({ tg }) => {
  const chatId = tg.initDataUnsafe?.user?.id ?? 123456;

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');

  const markAsOfficeAction = (event) => {
    markAsOffice(chatId)
    .then(() => navigate('/'));
  }

  useEffect(() => {
    getUserData(chatId)
      .then((response) => {
        if (response.user) {
          setFirstName(response.user.firstName);
        }
      });
  }, []);

  return (
    <div className={classes.remotePage}>

      <div style={{display: "flex", flexDirection: "column"}} >
        <Title/>

        <span className={classes.textHallo}>👋🏻 Привет, {firstName}</span>

        <div className={classes.statusBlock}>
          <span className={classes.textStatus}>Твой статус на базе:</span>

          <div className={classes.blockChooseStatus}>
            <OfficeStatus isActive={false} onClickAction={markAsOfficeAction}/>
            <RemoteStatus isActive={true}/>
          </div>
        </div>
      </div>


      <div className={classes.textBlock}>

        <span className={classes.textFirst}>
          Ты отметил(а) что сегодня работаешь удаленно, так что не будешь получать уведомления о перекурах и не сможешь их инициировать.
        </span>

        <span className={classes.textSecond}>
          Если хочешь снова участвовать в перекурах, поменяй свой статус офисный :)
        </span>

      </div>


      <CommonButton
        text={"Закрыть"}
        onClickAction={() => {
          if (tg) {
            tg.close()
          }
        }}
      />

    </div>
  );
};

export default RemotePage;