import React from 'react';
import classes from './RemoteStatus.module.css'

const RemoteStatus = ({isActive, onClickAction}) => {
  return (
    <div
      className={isActive ? classes.statusBody : classes.statusInactiveBody}
      onClick={onClickAction}
    >
      <span className={isActive ? classes.statusText : classes.statusInactiveText}>Удаленно 🤡</span>
    </div>
  );
};

export default RemoteStatus;