import React, {useState} from 'react';
import classes from './RegistrationPage.module.css'
import Title from '../../Component/Title/Title';
import NameInput from '../../Component/Inputs/NameInput';
import ComingButton from '../../Component/buttons/ComingButton/ComingButton';
import {registration} from '../../apiClient/apiClient';
import {useNavigate, useParams} from 'react-router-dom';

const RegistrationPage = ({ tg }) => {
  const firstName = tg.initDataUnsafe.user ? tg.initDataUnsafe.user['first_name'] : 'user';

  const navigate = useNavigate();
  const { userId } = useParams();

  const [name, setName] = useState(firstName ?? '');

  const registrationAction = async () => {
    const response = await registration(userId, name);

    if (response !== 'success') {
      console.log(new Error('registration fail'));
      return
    }

    navigate('/');
  }

  return (
    <div className={classes.page}>
      <Title/>

      <div className={classes.inputBlock}>
        <span className={classes.inputLegend}>
          Введи имя, которое будет использоваться в курилке
        </span>
        <NameInput placeholder={firstName} setName={setName} onKeyUpAction={registrationAction}/>
      </div>

      <ComingButton text={"Быть курильщиком!"} onClickAction={registrationAction}/>
    </div>
  );
};

export default RegistrationPage;