import React from 'react';
import classes from './RejectButton.module.css'

const RejectButton = ({ onClickAction }) => {
  return (
    <div
      className={classes.rejectButtonBackground}
      onClick={onClickAction}
    >
      <span className={classes.buttonText}>
        Сейчас не хочу
      </span>
    </div>
  );
};

export default RejectButton;