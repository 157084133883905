import React from 'react';
import classes from './ParticipantsList.module.css'
import ParticipantsListElement from './ParticipantListElement/ParticipantsListElement';

const ParticipantsList = ({participants, isHidden}) => {
  return (
    <div className={classes.participantsListBackground} style={isHidden ? { display: "none"} : {}}>
      {
        participants.map((participant) =>
          <ParticipantsListElement
            key={participant.id}
            name={participant.firstName}
            status={participant.status}
          />
        )
      }
    </div>
  );
};

export default ParticipantsList;