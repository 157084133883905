import './App.css';
import CallPage from './Page/CallPage/CallPage';
import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import InvitePage from './Page/InvitePage/InvitePage';
import RemotePage from './Page/RemotePage/RemotePage';
import RegistrationPage from './Page/RegistrationPage/RegistrationPage';
import NotFoundSessionPage from './Page/SessionWasCanceledPage/NotFoundSessionPage';
import AwaitingPage from './Page/AwaitingPage/AwaitingPage';
import RejectedCallPage from './Page/RejectedCallPage/RejectedCallPage';

const tg = window.Telegram.WebApp;

function App() {

  tg?.expand();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <CallPage tg={tg}/>,
    },
    {
      path: "/invite/:sessionId",
      element: <InvitePage tg={tg}/>
    },
    {
      path: "/remote",
      element: <RemotePage tg={tg}/>
    },
    {
      path: "/registration/:userId",
      element: <RegistrationPage tg={tg}/>
    },
    {
      path: "/notFound",
      element: <NotFoundSessionPage tg={tg}/>
    },
    {
      path: "/session/:sessionId",
      element: <AwaitingPage tg={tg}/>
    },
    {
      path: "/rejectedCall",
      element: <RejectedCallPage tg={tg}/>
    }
  ]);

  return (
    <div className="App">

      <RouterProvider router={router} />

    </div>
  );
}

export default App;
