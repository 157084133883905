import React, {useEffect, useState} from 'react';
import classes from './InvitePage.module.css';
import Title from '../../Component/Title/Title';
import ComingButton from '../../Component/buttons/ComingButton/ComingButton';
import RejectButton from '../../Component/buttons/RejectButton/RejectButton';
import CancelButton from '../../Component/buttons/CancelButton/CancelButton';
import {useNavigate, useParams} from 'react-router-dom';
import {acceptCallToMeet, getSession, markAsRemote, rejectCallToMeet} from '../../apiClient/apiClient';
import {checkParticipantIsVote} from './utils/checkParticipantIsVote';
import {socket} from '../../Socket';

const InvitePage = ({tg}) => {
  const chatId = tg.initDataUnsafe?.user?.id ?? 123456;

  const navigate = useNavigate();

  const { sessionId } = useParams();

  const [initiatorName, setInitiatorName] = useState('');

  useEffect(() => {
      getSession(sessionId)
        .then((response) => {
          if (response === 'notFound') {
            navigate('/notFound');
          }

          if (response === 'error') {
            console.log(response);
          }

          setInitiatorName(response.initiatorName);

          if (checkParticipantIsVote(chatId, response.participants)) {
            navigate(`/session/${sessionId}`);
          }
        });

      socket.connect();
      socket.on('cancelSession', (canceledSessionId) => navigate('/notFound'));

      return () => {
        socket.off('cancelSession');
        socket.disconnect();
      }
  }, [])

  const acceptInviteAction = async (event) => {
    acceptCallToMeet(sessionId, chatId)
      .then(() => {
        navigate(`/session/${sessionId}`);
      })
  }

  const rejectInviteAction = async (event) => {
    rejectCallToMeet(sessionId, chatId)
      .then(() => navigate('/rejectedCall'))
  }

  const markAsRemoteAction = async (event) => {
    markAsRemote(chatId, sessionId)
      .then(() => navigate('/remote'));
  }

  return (
    <div className={classes.invitePage}>
      <Title/>

      <div className={classes.textBlock}>
        <span className={classes.initializerName}>{initiatorName}</span>
        <span className={classes.inviteText}>зовет на перекур</span>
      </div>

      <div className={classes.answerButtonsBlock}>
        <ComingButton text={"Иду!"} onClickAction={acceptInviteAction}/>
        <RejectButton onClickAction={rejectInviteAction}/>
      </div>

      <div className={classes.remoteBlock}>
        <CancelButton text={'Сегодня удаленно ('} onClickAction={markAsRemoteAction}/>
        <span className={classes.remoteBlockText}>
          Не получать уведомления до завтрашнего дня
        </span>
      </div>

    </div>
  );
};

export default InvitePage;