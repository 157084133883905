import React from 'react';
import classes from './NameInput.module.css'

const NameInput = ({ placeholder, setName, onKeyUpAction }) => {
  return (
    <input
      className={classes.inputBackground}
      type="text"
      placeholder={placeholder}
      onInput={(event) => setName(event.currentTarget.value)}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          onKeyUpAction();
        }
      }}
    />
  );
};

export default NameInput;