import React from 'react';
import classes from './CallButton.module.css';

const CallButton = ({onClickAction, isPressed}) => {
  return (
    <div
      className={classes.callButton}
      onClick={(event) => onClickAction(event)}
      style={isPressed ? { display: 'none' } : {}}
    >
        <span className={classes.buttonText}>Позвать курить</span>
    </div>
  );
};

export default CallButton;