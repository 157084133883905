import React from 'react';
import classes from './AwaitingSvg.module.css'

const AwaitingSvg = () => {
  return (
    <div className={classes.loader}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{fill: "currentcolor"}}>
        <path d="M18.4058 20.035C16.2933 21.7412 13.6324 22.5537 10.9308 22.2897C8.798 22.0866 6.80737 21.2537 5.20269 19.8928H7.823V18.2678H2.54175V23.5491H4.16675V21.1319C6.01519 22.6959 8.3105 23.6709 10.7886 23.8944C11.1746 23.935 11.5402 23.9553 11.9058 23.9553C14.648 23.9553 17.2886 23.0209 19.4214 21.2741C23.3011 18.1459 24.8246 13.0475 23.3011 8.29437L21.7574 8.78187C23.0574 12.9256 21.7574 17.3334 18.4058 20.035Z" fill={"invert(1)"}/>
        <path d="M19.9168 2.99279C15.6106 -0.805648 9.04966 -1.06971 4.45903 2.66779C0.599659 5.79591 -0.923778 10.8537 0.559034 15.5865L2.10278 15.099C0.823097 11.0162 2.14341 6.62873 5.47466 3.94748C9.47622 0.697477 15.184 0.941227 18.9012 4.27248H16.2606V5.89748H21.5418V0.616227H19.9168V2.99279Z" fill={"invert(1)"}/>
      </svg>

    </div>
  );
};

export default AwaitingSvg;