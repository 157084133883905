import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function callToMeet(tlgUserId) {

  const response = await axios.post(`${BACKEND_URL}/callToMeet`, {
    initiatorChatId: tlgUserId,
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (response.status !== 200) {
    console.log('error callToMeet');
  }

  return response.data.sessionId;
}

export async function cancelSession(sessionId) {
  const response = await axios.post(`${BACKEND_URL}/cancelSession`, {
    sessionId: sessionId,
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (response.status !== 200) {
    console.log('error cancelSession');
    return 'error';
  }

  return 'success';
}


export async function markAsRemote(chatId, sessionId = undefined) {
  const body = {
    chatId: chatId
  }

  if (sessionId !== undefined) {
    body['sessionId'] = sessionId;
  }

  console.log(body);

  const response = await axios.post(`${BACKEND_URL}/markAsRemote`, body, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (response.status !== 200) {
    console.log('error markAsRemote');
    return 'error';
  }

  return 'success';
}


export async function markAsOffice(chatId) {
  const response = await axios.post(`${BACKEND_URL}/markAsOffice`, {
    chatId: chatId,
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (response.status !== 200) {
    console.log('error markAsOffice');
    return 'error';
  }

  return 'success';
}

export async function getParticipants(chatId) {
  const response = await axios.get(`${BACKEND_URL}/listParticipants?initiatorId=${chatId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (response.status !== 200) {
    console.log('error markAsOffice');
    return 'error';
  }

  return response.data;
}

export async function registration(id, name) {
  const response = await axios.post(`${BACKEND_URL}/registration`, {
    id: id,
    firstName: name
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (response.status !== 200) {
    console.log('error markAsOffice');
    return 'error';
  }

  return 'success';
}

export async function getUserData(chatId) {
  const response = await axios.get(`${BACKEND_URL}/getUserDataByChatId?chatId=${chatId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (response.status !== 200) {
    return 'error';
  }

  return response.data;
}

export async function getSession(sessionId) {
  try {
    const response = await axios.get(`${BACKEND_URL}/getSessionById?sessionId=${sessionId}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      return 'notFound';
    }
  }
}

export async function getActiveSession() {
  try {
    const response = await axios.get(`${BACKEND_URL}/getActiveSession`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return response.data;
  } catch (error) {
    return 'notFound';
  }
}

export async function acceptCallToMeet(sessionId, chatId) {
  try {
    const response = await axios.post(`${BACKEND_URL}/acceptInviteToMeet`, {
      sessionId: sessionId,
      participantChatId: chatId,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return 'notFound';
  }
}

export async function rejectCallToMeet(sessionId, chatId) {
  try {
    const response = await axios.post(`${BACKEND_URL}/rejectInviteToMeet`, {
      sessionId: sessionId,
      participantChatId: chatId,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return 'notFound';
  }
}