import React from 'react';
import classes from './ParticipantsListElement.module.css'
import ParticipantStatus from '../../SVG/Statuses/ParticipantStatus';

const ParticipantsListElement = ({ chatId, name, isStatusHidden, status }) => {
  return (
    <div className={classes.participantBackground}>

      <ParticipantStatus status={status}/>

      <span className={classes.participantName}>{name}</span>

    </div>
  );
};

export default ParticipantsListElement;