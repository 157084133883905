import React from 'react';
import classes from './NotFoundSessionPage.module.css'
import Title from '../../Component/Title/Title';
import CommonButton from '../../Component/buttons/CommonButton/CommonButton';

const NotFoundSessionPage = ({tg}) => {
  return (
    <div className={classes.pageBackground}>
      <Title/>

      <div className={classes.textBlock}>
        <span className={classes.textAboutCancel}>
          Перекур отменили 😢
        </span>

        <span className={classes.textAboutNotExist}>
          Или его никогда не существовало...
        </span>
      </div>

      <CommonButton
        text={"Закрыть"}
        onClickAction={() => {
          if (tg) {
            tg.close()
          }
        }}
      />
    </div>
  );
};

export default NotFoundSessionPage;