import React from 'react';
import classes from './RejectedCallPage.module.css'
import Title from '../../Component/Title/Title';
import CommonButton from '../../Component/buttons/CommonButton/CommonButton';

const RejectedCallPage = ({tg}) => {
  return (
    <div className={classes.pageBackground}>
      <Title/>

      <div className={classes.textBlock}>
        <span className={classes.textAboutCancel}>
          Очень жаль 😢
        </span>

        <span className={classes.textAboutNotExist}>
          Ждем тебя в следующий раз!
        </span>
      </div>

      <CommonButton
        text={"Закрыть"}
        onClickAction={tg?.close()}
      />
    </div>
  );
};

export default RejectedCallPage;