import React from 'react';
import classes from './CancelButton.module.css';

const CancelButton = ({ isHidden, onClickAction, text }) => {
  return (
    <div
      className={classes.cancelButtonBody}
      style={isHidden ? { display: 'none' } : {}}
      onClick={onClickAction}
    >
      <span className={classes.cancelButtonText}>{text}</span>
    </div>
  );
};

export default CancelButton;