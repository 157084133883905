import React from 'react';
import AwaitingSvg from './AwaitingSvg/AwaitingSvg';

const ParticipantStatus = ({ status }) => {

  if (status === undefined) return;

  if (status === 'awaiting') {
    return (<AwaitingSvg/>);
  }

  if (status === 'accept') {
    return (
      <div style={{display: "flex"}}>
        <span style={{userSelect: "none"}}>
          ✅
        </span>
      </div>
    )
  }

  if (status === 'reject') {
    return (
      <div style={{display: "flex"}}>
        <span style={{userSelect: "none"}}>
          ❌
        </span>
      </div>
    )
  }
 };

export default ParticipantStatus;